const Privacy = () => {
  document.title = "Политика конфиденциальности | Сенатмед"
  window.scrollTo({ top: 0, behavior: 'auto' })
  return (
    <section className="privacy">
      <div className="container">
        <div className="privacy__inner">
          <div className="privacy__text-wrapper"><p></p>
            <p><span style={{fontSize: 14}}>Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую сайт, расположенный на доменном имени<span></span></span><span style={{fontSize: 14}}><a href="https://www.senatmed.ru"> www.senatmed.ru </a></span><span style={{fontSize: 14}}>, может получить о Пользователе во время использования сайта.</span></p>
            <ol>
            <li>ОПРЕДЕЛЕНИЕ ТЕРМИНОВ<br /> 1.1 В настоящей Политике конфиденциальности используются следующие термины: «Администрация сайтаООО «Сенатмед» (далее – Администрация сайта) – уполномоченные сотрудники на управления сайтом, действующие от имени владельца сайта, которые организуют и (или) осуществляет обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</li>
            </ol>
            <p>1.1.2. «Персональные данные» — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных).</p>
            <p>1.1.3. «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
            <p>1.1.4. «Конфиденциальность персональных данных» — обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
            <p>1.1.5. «Пользователь сайта, далее — Пользователь.</p>
            <p>1.1.6. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя, который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть страницу соответствующего сайта.</p>
            <p>1.1.7. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.</p>
            <ol start="2">
            <li>ОБЩИЕ ПОЛОЖЕНИЯ<br /> Использование Пользователем сайта формы обратной связи означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя.</li>
            </ol>
            <p>В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование сайта<a href="https://www.senatmed.ru"> www.senatmed.ru </a></p>
            <p>Настоящая Политика конфиденциальности применяется только к сайту<a href="https://www.senatmed.ru/"> www.senatmed.ru </a></p>
            <p>Сайт не контролирует и не несет ответственность за страницы и сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайте<a href="https://www.senatmed.ru/"> www.senatmed.ru </a></p>
            <p>Администрация сайта не проверяет достоверность персональных данных, предоставляемых Пользователем сайта.</p>
            <ol start="3">
            <li>ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ<br /> 3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации сайтапо неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Администрации сайта при заполнении формы обратной связи на сайте для заказа на съемку.</li>
            </ol>
            <p>3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики конфиденциальности, предоставляются Пользователем путём заполнения формы обратной связина сайте<a href="https://www.senatmed.ru/"> www.senatmed.ru </a>и включают в себя следующую информацию:</p>
            <p>3.2.1. Имя (nickname);</p>
            <p>3.2.2. адрес электронной почты (e-mail).</p>
            <ol start="4">
            <li>ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ<br /> 4.1. Персональные данные Пользователя Администрация сайта может использовать в целях:</li>
            </ol>
            <p>4.1.1. Идентификации Пользователя, посетившегосайтдля связи с Администрацией сайта.</p>
            <p>4.1.2. Установления с Пользователем обратной связи.</p>
            <p>4.1.3. Осуществления рекламной деятельности с согласия Пользователя.</p>
            <ol start="5">
            <li>СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ<br /> 5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с использованием средств автоматизации или без использования таких средств.</li>
            </ol>
            <p>5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации.</p>
            <p>5.3. Администрация сайта принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.</p>
            <ol start="6">
            <li>ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ<br /> 6.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.</li>
            </ol>
            <p>6.2. Новая Политика конфиденциальности вступает в силу с момента еë размещения на Сайте-портфолио, если иное не предусмотрено новой редакцией Политики конфиденциальности.</p>
            <p>6.3. Все предложения или вопросы по настоящей Политике конфиденциальности следует сообщать на электронный адрес<span> </span>senatmed.info@gmail.com</p>
            <p>6.4. Действующая Политика конфиденциальности размещена на странице по адресу https://senatmed.ru/privacy-policy/.</p></div>
        </div>
      </div>
    </section>
  )
}

export default Privacy